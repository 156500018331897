<template>
  <div>
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="pageTotal"
      use-router
      @page-click="changeRoute"
    ></b-pagination-nav>
  </div>
</template>
      
<script>
import store from "@/store";
import {
  ref, onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils';
import { BPaginationNav } from "bootstrap-vue";

export default {
  components: {
    BPaginationNav,
  },
  props: {
    pageName: {
      type: String,
      default: "",
    },
    pageTotal: {
      type: Number,
    },
    pageNum: {
      type: [Number, String],
      default: [1, "1"],
    },
    fetchDatas: {
      type: Function,
    },
  },
  setup(props, { emit }) {

    const changeRoute = (e) => {
      console.log(e)
    }

    const linkGen = (pageNum) => {
      return `/my-pages/${props.pageName}?page=${pageNum}`
    };
    return {
      linkGen,
      changeRoute,
    };
  },
};
</script>
      