var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.spinner === true)?[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',[_c('div',{staticClass:"text-center m-5"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1)])])]:_c('b-card',{staticClass:"mb-0 card-company-table",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('router-link',{attrs:{"to":{
                  name: 'add-new',
                  query: {
                    categories: JSON.stringify(_vm.categories),
                    isNewsMedia: true,
                    isEdit: false,
                  },
                }}},[_c('b-button',{attrs:{"variant":"primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Add New News Media")])],1)],1)],1)])],1)],1),_c('Table',{attrs:{"fields":_vm.fields,"items":_vm.fetchedDatas,"isNewsMedia":true,"routeName":"news-media-details","pageName":"news-media","fetchDatas":_vm.fetchDatas,"pageNum":_vm.pageNum,"pageTotal":_vm.resDatas.last_page}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }