<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <!-- <b-col md="6">
              <FCategoriesCom urlName="events" />
            </b-col> -->

            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <router-link
                  :to="{
                    name: 'add-new',
                    query: {
                      categories: JSON.stringify(categories),
                      isNewsMedia: true,
                      isEdit: false,
                    },
                  }"
                >
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New News Media</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- News and Media Table -->
        <!-- <Table
          :fields="fields"
          :items="fetchedDatas"
          :isNewsMedia="true"
          routeName="event-details"
          @editFunc="editData"
          @delFunc="delData"
          :fetchDatas="fetchDatas"
        /> -->
        <Table
          :fields="fields"
          :items="fetchedDatas"
          :isNewsMedia="true"
          routeName="news-media-details"
          pageName="news-media"
          :fetchDatas="fetchDatas"
          :pageNum="pageNum"
          :pageTotal="resDatas.last_page"
        />
      </b-card>
    </b-card>
  </div>
</template>
  
  <script>
import store from "@/store";
import Table from "../components/Table.vue";
import { useRouter } from "@core/utils/utils";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import router from "@/router";
import {
  ref,
  reactive,
  computed,
  watch,
  // ref, watch, computed, onUnmounted,
} from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormDatepicker,
    Table,
    MyPhotoUpload,
    FCategoriesCom,
  },
  setup() {
    const { route, router } = useRouter();
    const fields = [
      { key: "id", label: "#" },
      { key: "title", label: "title" },
      { key: "sub_title", label: "sub title" },
      { key: "category.title", label: "category" },
      { key: "posted_at", label: "posted_at" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const pageNum = ref(1);
    const fetchedDatas = ref([]);
    const resDatas = ref([]);
    const categories = ref([]);

    //Datas Fetching
    const fetchDatas = () => {
      spinner.value = true;
      if (router.app._route.query.page) {
        pageNum.value = router.app._route.query.page;
      }
      if (router.app._route.query.category_id) {
        pageNum.value = router.app._route.query.category_id;
      }
      let queryKey = Object.keys(router.app._route.query)[0];
      store
        .dispatch(
          "app-mypages/fetchDatas",
          `/our_news?${queryKey}=${pageNum.value}`
        )
        .then((response) => {
          fetchedDatas.value = response.data.data.data;
          resDatas.value = response.data.data;
          spinner.value = false;
        });
    };
    // const fetchDatas = () => {
    //   spinner.value = true;
    //   store
    //     .dispatch(
    //       "app-mypages/fetchDatas",
    //       `/our_news`
    //     )
    //     .then((response) => {
    //       fetchedDatas.value = response.data.data.data;
    //       console.log(fetchedDatas.value)
    //       spinner.value = false;
    //     });
    // };
    fetchDatas();
    // Route Params
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchDatas();
    });

    const fetchCategories = () => {
      store
        .dispatch("app-mypages/fetchDatas", "/our_news_categories")
        .then((response) => {
          categories.value = response.data.data.data.filter(c => {
            return c.title !== "All";
          });
        });
    };
    fetchCategories();

    return {
      fields,
      spinner,
      pageNum,
      fetchedDatas,
      resDatas,
      categories,
      fetchDatas,
    };
  },
};
</script>
  