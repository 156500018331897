<template>
  <div>
    <!-- Table -->
    <b-table responsive bordered :items="props.items" :fields="props.fields" class="mb-2">
      <!-- id -->
      <template #cell(id)="data">
        <div class="d-flex align-items-center">
          <div class="font-weight-bolder">
            {{ data.item.id }}
          </div>
        </div>
      </template>

      <!-- active -->
      <template #cell(active)="data">
        <div class="d-flex align-items-center">
          <div class="font-weight-bolder">
            {{ data.item.active === 1 ? 'active' : 'unactive' }}
          </div>
        </div>
      </template>

      <!-- description -->
      <template #cell(description)="data">
        <!-- <div class="d-flex align-items-center">
          <span v-html="data.value" class="text-break"></span>
        </div> -->
        <div>
          <b-button pill size="sm" variant="primary" @click="$emit('desDetail', data.item)"
            v-b-modal.modal-scrollable-des>
            <span class="text-nowrap">Details</span>
          </b-button>
        </div>
      </template>

      <!-- image -->
      <template #cell(photo_url)="data">
        <div class="">
          <img :src="data.item.photo.name" class="tphoto" alt="photo" />
        </div>
      </template>
      <template #cell(images)="data">
        <div>
          <b-button pill size="sm" variant="primary" @click="$emit('photosDetails', data.item)"
            v-b-modal.modal-scrollable-photos>
            <span class="text-nowrap">Details</span>
          </b-button>
        </div>
      </template>

      <!-- Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>

          <!-- <b-dropdown-item v-if="isNewsMedia">
            <router-link :to="{
              name: routeName,
              // params: { serviceId: data.item.id }
              // query: { serviceDetails: JSON.stringify(data.item) },
              query: { id: data.item.id },
            }" class="dropdown-item-con">
              <feather-icon icon="BookOpenIcon" />
              <span class="align-middle ml-50">Details</span>
            </router-link>
          </b-dropdown-item> -->

          <router-link v-if="isNewsMedia" :to="{
            name: routeName,
            // params: { serviceId: data.item.id }
            // query: { serviceDetails: JSON.stringify(data.item) },
            query: { id: data.item.id },
          }" class="dropdown-item-con">
            <feather-icon icon="BookOpenIcon" />
            <span class="align-middle ml-50">Details</span>
          </router-link>

          <b-dropdown-item v-if="isCategories" @click="$emit('editFunc', data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="isCategories" @click="$emit('delFunc', data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- Pagination -->
    <template v-if="isNewsMedia">
      <div class="float-right mr-5">
        <MyPagination :fetchDatas="fetchDatas" :pageTotal="pageTotal" :pageName="pageName" :pageNum="pageNum" />
      </div>
    </template>
  </div>
</template>
    
<script>
import { ref } from "@vue/composition-api";
import MyPagination from "./MyPagination.vue";
import {
  BTable,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormRating,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormRating,
    MyPagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    isNewsMedia: {
      type: Boolean,
      default: false,
    },
    isCategories: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "",
    },
    pageName: {
      type: String,
      default: "",
    },
    pageTotal: {
      type: Number,
    },
    pageNum: {
      type: [Number, String],
      default: [1 | "1"],
    },
    fetchDatas: {
      type: Function,
    },
  },
  setup(props, { emits }) {
    return {
      props,
      emits,
    };
  },
};
</script>

<style scoped>
.dropdown-item-con {
  display: block;
  font-weight: 400;
  color: #6e6b7b;
  padding: 0.65rem 1.28rem;
}

.dropdown-item-con:hover {
  color: #7367f0;
}

.tphoto {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
</style>