<template>
  <div>
    <!-- F Categories -->
    <div class="mb-2">
      <label for="categories" class="font-weight-bolder">Search by Category :</label>
      <b-form-select v-model="filter_category_id" @change="fetchFilterCatDatas">
        <b-form-select-option disabled value=""
          >Search by Category</b-form-select-option
        >
        <template v-if="isService">
          <b-form-select-option
          v-for="c in category_type_service"
          :key="c.id"
          :value="c.id"
          >
          {{ c.name }}
        </b-form-select-option>
        </template>

        <template v-if="isNews">
          <b-form-select-option
          v-for="c in category_type_news"
          :key="c.id"
          :value="c.id"
          >
          {{ c.name }}
        </b-form-select-option>
        </template>
      </b-form-select>
    </div>
  </div>
</template>
    
  <script>
import store from "@/store";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import {
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    urlName: {
      type: String,
      default: "",
    },
    isService: {
      type: Boolean,
      default: false,
    },
    isNews: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {emit}) {
    const { route, router } = useRouter();
    const filter_category_id = ref("");
    const categories = ref([]);
    const category_name = ref("");
    const category_type_service = ref([]);
    // const category_type_event = ref([]);
    const category_type_blog = ref([]);
    const category_type_noti = ref([]);
    const category_type_news = ref([]);

    //Datas Fetching
    const fetchFilterCatDatas = () => {
      router.push(`/my-pages/${props.urlName}?category_id=${filter_category_id.value}`);
    };

    const fetchCategories = () => {
      store
        .dispatch("app-mypages/fetchDatas", "categories")
        .then((response) => {
          categories.value = response.data.data;

          category_type_service.value = categories.value.filter(ts => ts.category_type === 'service');
          // category_type_event.value = categories.value.filter(te => te.category_type === 'event');
          category_type_blog.value = categories.value.filter(tb => tb.category_type === 'blog');
          category_type_noti.value = categories.value.filter(tn => tn.category_type === 'notification');
          category_type_news.value = [...category_type_blog.value, ...category_type_noti.value]

          emit("getServiceCategories", category_type_service.value);
          // emit("getEventCategories", category_type_event.value);
          emit("getNewsCategories", category_type_news.value);
        });
    };
    fetchCategories();

    return {
      filter_category_id,
      fetchFilterCatDatas,
      categories,
      category_type_service,
      category_type_news,
      category_name,
    }
  },
};
</script>