<template>
  <div>
    <div>
      <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        dragText="Drag images (multiple)"
        browseText="(or) Select"
        primaryText="First Photo"
      ></vue-upload-multiple-image>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import { BCard, BButton, BSpinner, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    VueUploadMultipleImage,
  },
  setup(props, { emit }) {
    const photoId = ref("");

    const uploadImageSuccess = (formData, index, fileList) => {
      const file = formData.get("file");
      formData.set("name", file);
      store
        .dispatch("app-mypages/postDatas", { path: "photos", payload: formData })
        .then((response) => {
          photoId.value = response.data.data.id;
          emit("getPhotoId", photoId.value);
        });
    };

    const beforeRemove = (index, done, fileList) => {
      if (true) {
        store
          .dispatch("app-mypages/delData", { path: "photos", id: photoId.value })
          .then((response) => {
            done();
          });
      }
    };

    // const editImage = (formData, index, fileList) => {
    //   imageFormFileData.value = fileList[0].path;
    // };

    return {
      uploadImageSuccess,
      beforeRemove,
      // editImage,
    };
  },
};
</script>
  